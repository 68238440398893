//====================================================
//  Function: Scroll & resize events
//====================================================
var windowScrollTop
var windowWidth

if ($('.js-navigation').length) {
  var navigation = $('.js-navigation')
}

function partScrollResize() {
  windowScrollTop = $(window).scrollTop()
  windowWidth = $(window).width()

  if ($('.js-navigation').length) {
    if (windowScrollTop > 1) {
      navigation.addClass('is-scrolling')
    } else {
      navigation.removeClass('is-scrolling')
    }
  }
}
