//====================================================
//  Function: Scroll to
//====================================================
function partScrollTo() {
  if ($('.js-scroll-trigger').length) {
    $('.js-scroll-trigger').bind('click', function () {
      var inst = $(this)
      var target = $(this).attr('data-scroll')
      var navigationHeight = $('.js-navigation').height()
      if ($(window).width() > bpMedium) {
        var navigationHeight = 105
      } else {
        var navigationHeight = -10
      }
      if (target.length) {
        if ($('#' + target + '').length != 0) {
          if (
            $('.js-navigation').hasClass('is-collapse') &&
            $(window).width() < bpMedium
          ) {
            $('.js-navigation').removeClass('is-collapse')
          }
          $('html, body')
            .stop()
            .animate({
              scrollTop: $('#' + target + '').offset().top - navigationHeight,
            })
          return false
        }
      }
    })
  }
}
