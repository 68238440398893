function cookieInit() {
  var language = $('html').attr('lang')
  // obtain plugin
  var cc = initCookieConsent()

  // run plugin with your configuration
  cc.run({
    current_lang: language,
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },

    onAccept: function (cookie) {
      // ...
    },

    onChange: function (cookie, changed_preferences) {
      // ...
    },

    languages: {
      en: {
        consent_modal: {
          title: 'WE CARE ABOUT DATA. </br> ESPECIALLY YOURS.',
          description:
            'This website processes your cookies and uses them for Analytics and Ads targeting. </br> You can switch them off, customize the settings or accept their processing.',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Cookie settings',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookies settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          blocks: [
            // {
            //   title: 'First block title ...',
            //   description: 'First block description ...',
            // },
            {
              title: 'Technical or functional cookies',
              // description: 'Second block description ...',
              toggle: {
                value: 'my_category1',
                enabled: true,
                readonly: true,
              },
            },
            {
              title: 'Statistical cookies',
              // description: 'Third block description ...',
              toggle: {
                value: 'my_category2',
                enabled: true,
                readonly: false,
              },
            },
            {
              title: 'Marketing / Advertising cookies',
              // description: 'Third block description ...',
              toggle: {
                value: 'my_category3',
                enabled: true,
                readonly: false,
              },
            },
          ],
        },
      },
      sk: {
        consent_modal: {
          title: 'STARÁME SA O ÚDAJE. </br> Hlavne tie Vaše.',
          description:
            'Táto webová stránka spracováva vaše cookies a používa ich na Analytics a zacielenie reklám. </br> Môžete ich vypnúť, prispôsobiť nastavenia alebo akceptovať ich spracovanie.',
          primary_btn: {
            text: 'Súhlasím so všetkým',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Nastavenia cookies',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Nastavenia cookies',
          save_settings_btn: 'Uložiť nastavenia',
          accept_all_btn: 'Prijať všetko',
          blocks: [
            // {
            //   title: 'First block title ...',
            //   description: 'First block description ...',
            // },
            {
              title: 'Technické alebo funkčné cookies',
              // description: 'Second block description ...',
              toggle: {
                value: 'my_category1',
                enabled: true,
                readonly: true,
              },
            },
            {
              title: 'Štatistické cookies',
              // description: 'Third block description ...',
              toggle: {
                value: 'my_category2',
                enabled: true,
                readonly: false,
              },
            },
            {
              title: 'Marketingové / reklamné cookies',
              // description: 'Third block description ...',
              toggle: {
                value: 'my_category3',
                enabled: true,
                readonly: false,
              },
            },
          ],
        },
      },
    },
  })
}
